/* import { httpRequest } from '@/services/httpRequest.service'
import Vue from 'vue' */

const mutations = {
    
}

const actions = {
    
}

const getters = {
    
}

const state = () => ({
    specificationMasterMaterialList: [],
})

export default {
    mutations,
    getters,
    actions,
    state,
}

