import { httpRequest } from '@/services/httpRequest.service'
//import _ from 'lodash'
//import Vue from 'vue'

const mutations = {
    STOCK_SET_LIST(state, data){
        if(typeof data.options != 'undefined'){
            state.stockOptions = data.options
        } 
        if(data.data != [] && data.data != null && typeof data.data != 'undefined'){
            state.stockList = data.data
        }else{
            state.stockList = []
        }
    },
    STOCK_SET_OPTIONS(state, options){
        state.stockOptions = options
    },
    STOCK_SET_ERROR(state, error){
        state.stockError = error
    },
    STOCK_LOADING(state){
        state.stockLoading = !state.stockLoading
    }
}

const actions = {
    async stockSetListActions({commit}){

        commit('STOCK_LOADING')
        const data = await httpRequest('buisness/company/stock', 'post', { 
            options: getters.stockOptionsGetter
        })
        commit('STOCK_LOADING')

        if(data.status == 500){
            commit('STOCK_SET_ERROR', 'Ошибка на сервере')
        }
        else if(data.code == 200){
            commit('STOCK_SET_LIST', data)
        }
        else{
            commit('STOCK_SET_ERROR', data.data)
        }
    }
}

const getters = {
    stockListGetter: (state) => state.stockList,
    stockErrorGetter: (state) => state.stockErrorGetter,
    stockLoadingGetter: (state) => state.stockLoading,
    stockOptionsGetter: (state) => state.stockOptions
}

const state = () => ({
    stockList: [],
    stockError: '',
    stockLoading: false,
    stockOptions: {
        pagginate: {
            page: 1,
            pages: 1,
            limit: 10
        },
        orderBy: {
            name: "ASC",
        }
    }
})

export default {
    mutations,
    getters,
    actions,
    state,
}
