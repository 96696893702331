import { httpRequest } from '@/services/httpRequest.service'
//import _ from 'lodash'
import Vue from 'vue'

const mutations = {
    SPECIFICATION_MATERIAL_ERROR(state, error){     
        state.specificationMaterialError = error
    },  
    SPECIFICATION_MATERIAL_CLEAR(state){
        state.specificationMaterialList = []
        state.specificationMaterialSaveIs = true
    },  
    SPECIFICATION_MATERIAL_ADD_ROW(state){
        var rowKey = String(Math.random())
        var index = state.specificationMaterialList.length == 0 
            ? 1
            : state.specificationMaterialList[state.specificationMaterialList.length - 1].index + 1

        state.specificationMaterialList.push({
            id: '',
            index: index,
            saveIs: true,
            deleteIs: true,
            position: '',
            fullname: '',
            type: '',
            description: '',
            code: '',
            rowKey: rowKey,
            unit: '',
            quantity: 0,
            vendor: '',
            material: null,
            is_group: false
        })
    },
    SPECIFICATION_MATERIAL_SET_LIST(state, data){

        let material = []
        state.specificationMaterialError = ''

        if(data.length != 0) {

            for( var i = 0; i < data.length; i++){
                if(data[i].is_group == true){
                    material.push({
                        ...data[i],
                        rowKey: String(Math.random()),
                        saveIs: true,
                    })
                }else{
                    material.push(
                        { ...data[i], unit: data[i].unit.title, saveIs: true, rowKey: String(Math.random()) }
                    )
                }
            }
        }

        for(let i = 0; i < material.length; i++){
            state.specificationMaterialList.splice(
                state.specificationMaterialList.indexOf(state.specificationMaterialList.filter( item => item.index == material[i].index)[0]),
                1,
                material[i]
            )
        }   

        state.specificationMaterialList = state.specificationMaterialList.sort( (a, b) => Number(a.index) - Number(b.index) )
    },
    SPECIFICATION_MATERIAL_GET_HISTORY_LIST(state, data){
        state.specificationMaterialError = ''
        state.specificationMaterialHistoryList = data
    },
    SPECIFICATION_MATERIAL_ADD_GROUP(state, group){

        let index = state.specificationMaterialList.indexOf(state.specificationMaterialList.filter( item => item.index == group.index)[0])

        Vue.set(state.specificationMaterialList, index, group)
        state.specificationMaterialSaveIs = false

    },
    SPECIFICATION_MATERIAL_EDIT(state, material){

        console.log('edit material')
        console.log(material)

        let index = state.specificationMaterialList.indexOf(state.specificationMaterialList.filter( item => item.rowKey == material.rowKey)[0])

        console.log(index)

        Vue.set(state.specificationMaterialList, index, { 
            ...material,
            saveIs: false
        })

        state.specificationMaterialSaveIs = false
    },
    SPECIFICATION_MATERIAL_COPY(state, params){

        var indexs
        var endIndex 
        var startIndex

        startIndex = state.specificationMaterialList.filter(item => item.rowKey == params.rangeKeys.startRowKey)[0].index
        endIndex = state.specificationMaterialList.filter(item => item.rowKey == params.rangeKeys.endRowKey)[0].index
        indexs = Array.from({ length: endIndex + 1 - startIndex }, (_, i ) => i += startIndex );

        indexs.forEach( ( item ) => {

            if(state.specificationMaterialList[item].id != ''){                  
                state.specificationMaterialList[item] = {
                    ...state.specificationMaterialList[item],
                    ...params.data[item],
                    position: state.specificationMaterialList[item].position,
                    saveIs: false
                }
            }else{
                state.specificationMaterialList[item].saveIs = false
            }

        })
        /*
        for( var i = 0; i <= indexs.length; i++){
            
            if(state.specificationMaterialList[i].id != ''){
                state.specificationMaterialList[i] = {
                    ...state.specificationMaterialList[i],
                    ...params.data[i],
                    position: state.specificationMaterialList[i].position,
                    saveIs: false
                }
            }else{
                state.specificationMaterialList[i].saveIs = false
            }
        } */

        state.specificationMaterialSaveIs = false
    },
    SPECIFICATION_MATERIAL_AUTOFILE(state, params){

        var indexs = []

        for(let i = 0; i < params.targetSelectionData.length; i++) {
            indexs.push( state.specificationMaterialList.indexOf(state.specificationMaterialList.filter( item => item.rowKey == params.targetSelectionData[i].rowKey)[0]))
        }

        /*
        for (let i = 0; i < indexs.length; i++) {
            console.log(state.specificationMaterialList[indexs[i]])
            state.specificationMaterialList.splice(indexs[i], 1, {
                ...params.sourceSelectionData[(i + indexs.length) % params.sourceSelectionData.length],
                ...state.specificationMaterialList[indexs[i]],
                saveIs: false
            })
        } */

        // по row key получ всю строку источника

        for (let i = 0; i < indexs.length; i++) {
            state.specificationMaterialList.splice(indexs[i], 1, {
                ...state.specificationMaterialList[indexs[i]],
                ...params.sourceSelectionData[(i + indexs.length) % params.sourceSelectionData.length],
                is_group: state.specificationMaterialList.filter( item => item.rowKey == params.sourceSelectionData[(i + indexs.length) % params.sourceSelectionData.length].rowKey)[0].is_group,
                rowKey: state.specificationMaterialList[indexs[i]].rowKey,
                index: state.specificationMaterialList[indexs[i]].index,
                saveIs: false
            })
        } 

        state.specificationMaterialSaveIs = false
    },
    SPECIFICATION_MATERIAL_ADD_ABOVE(state, params){

        if(params.rowIndex == 0){
            let list = state.specificationMaterialList.slice(0, state.specificationMaterialList.length + 1).map( item => item = {
                ...item,
                index: item.index + 1,
                saveIs: false
            })

            state.specificationMaterialList = [{
                id: '',
                index: 0,
                saveIs: true,
                deleteIs: true,
                position: "",
                fullname: '',
                type: '',
                description: '',
                code: '',
                rowKey: String(Math.random()),
                unit: '',
                quantity: 0,
                vendor: '',
                material: '',
            }].concat(list)
        }else{
            let beforeList = state.specificationMaterialList.slice(0, params.rowIndex + 1).map( item => item = {
                ...item,
                saveIs: false
            })
    
            beforeList[beforeList.length - 1] = {
                id: '',
                index: Number(beforeList[beforeList.length - 1].index),
                saveIs: true,
                deleteIs: true,
                position: '',
                fullname: '',
                type: '',
                description: '',
                code: '',
                rowKey: String(Math.random()),
                unit: '',
                quantity: 0,
                vendor: '',
                material: '',
            }
    
            let afterList = state.specificationMaterialList.slice(params.rowIndex , state.specificationMaterialList.length).map( item => item = {
                ...item,
                index: item.index + 1,
                saveIs: false
            })

            console.log(afterList)

            console.log(beforeList[beforeList.length - 1])
            console.log(afterList[0])
    
            console.log('concat')
            console.log(beforeList.concat(afterList))
            state.specificationMaterialList = beforeList.concat(afterList)
        }

        state.specificationMaterialSaveIs = false
    },
    SPECIFICATION_MATERIAL_ADD_BELOW(state, params){

        let beforeList = state.specificationMaterialList.slice(0, params.rowIndex + 1)
        
        let afterList = state.specificationMaterialList.slice(params.rowIndex + 1, state.specificationMaterialList.length).map( item => item = {
            ...item,
            position:  item.position,
            index: item.index + 1,
            saveIs: false
        })

        beforeList.push({
            id: '',
            index: Number(afterList[0].index) - 1,
            saveIs: true,
            deleteIs: true,
            position: '',
            fullname: '',
            type: '',
            description: '',
            code: '',
            rowKey: String(Math.random()),
            unit: '',
            quantity: 0,
            vendor: '',
            material: '',
        })

        state.specificationMaterialList = beforeList.concat(afterList)
        state.specificationMaterialSaveIs = false
    },
    SPECIFICATION_MATERIAL_CLEAR_CELL(state, selectionRangeIndexes){
        let indexs = Array.from({ length: selectionRangeIndexes.endRowIndex
            + 1 - selectionRangeIndexes.startRowIndex }, (_, i ) => i += selectionRangeIndexes.startRowIndex
        );
        
        for(var i = 0; i < indexs.length; i++){      
            Vue.set(state.specificationMaterialList, indexs[i], { 
                ...state.specificationMaterialList[indexs[i]],
                saveIs: false
            })
        }

        state.specificationMaterialSaveIs = false
    },
    SPECIFICATION_MATERIALS_SET_MATERIAL(state, params){

        let index = 0

        index = state.specificationMaterialList.findIndex(item => item.rowKey == params.rowKey)

        console.log(params.material)
        
        if(state.specificationMaterialList[index].fullname == '' & state.specificationMaterialList[index].code == ''){
            state.specificationMaterialList.splice(index, 1, {
                ...state.specificationMaterialList[index],
                fullname: state.specificationMaterialList[index].fullname == '' ? params.material.name : state.specificationMaterialList[index].fullname,
                type: state.specificationMaterialList[index].type == '' ? params.material.type : state.specificationMaterialList[index].type,
                code: state.specificationMaterialList[index].code == '' ? params.material.code : state.specificationMaterialList[index].code,
                description: state.specificationMaterialList[index].description == '' ? params.material.description : state.specificationMaterialList[index].description,
                vendor: state.specificationMaterialList[index].vendor == '' ? params.material.vendor :  state.specificationMaterialList[index].vendor,
                unit: params.code.title,
                material: params.material,
                saveIs: false
            })  
        } else {
            state.specificationMaterialList.splice(index, 1, {
                ...state.specificationMaterialList[index],
                fullname: params.material.name,
                type: params.material.articul,
                code: params.material.code,
                description: params.material.description,
                vendor: params.material.vendor,
                unit: params.code.title,
                material: params.material,
                saveIs: false
            })      
        }
        
        state.specificationMaterialSaveIs = false
    },
    SPECIFICATION_MATERIAL_LOADING(state){
        state.specificationMaterialLoading = !state.specificationMaterialLoading
    },
    SPECIFICATION_MATERIAL_EDIT_LOADING(state){
        state.specificationMaterialEditLoading = !state.specificationMaterialEditLoading
    },
    SPECIFICATION_MATERIAL_ADD_SEARCH(state, data){
        state.specificationMaterialDirectoryList = []
        state.specificationMaterialDirectoryList = data
    },
    SPECIFICATION_MATERIAL_SAVE(state){
        state.specificationMaterialSaveIs = true 
        
        let savedList = state.specificationMaterialList.filter( item => item.saveIs != true)
        
        for(let i = 0; i < savedList.length; i++){
            
            let index = state.specificationMaterialList.indexOf(savedList[i])

            console.log(index)

            Vue.set(state.specificationMaterialList, index , { 
                ...savedList[i],
                saveIs: true
            })
        }
    },
    SPECIFICATION_MATERIAL_DELETE_FOR_POSITION(state, position){

        var index = state.specificationMaterialList.findIndex(item => item.position == position)
        state.specificationMaterialDeleteList.push(state.specificationMaterialList[index])

        state.specificationMaterialList.splice(index, 1, {
            ...state.specificationMaterialList[index],
            material: null,
        })  

        state.specificationMaterialSaveIs = false
    },
    SPECIFICATION_MATERIAL_DELETE_ROWS(state, materials){   

        let afterList
        let beforeList

        state.specificationMaterialDeleteList.push( ...materials.filter(item => item.id != ''))

        let deleteIndexs = materials.map(deleteItem => deleteItem.index)

        state.specificationMaterialList = state.specificationMaterialList.filter( item => !deleteIndexs.find( index => index == item.index)  )
        
        if(Number(materials[0].index) == 1){

            beforeList = state.specificationMaterialList
                .slice(Number(materials[materials.length - 1].index), state.specificationMaterialList.length)
                .map( item => item = {
                    ...item,
                    index:  Number(item.index) - materials.length,
                    saveIs: false
                })

            state.specificationMaterialList = beforeList

        }else{
            afterList = state.specificationMaterialList.slice(0, Number(materials[0].index))

            let beforeList = state.specificationMaterialList
                .filter( item => item.index > afterList[afterList.length - 1].index)
                .map( item => item = {
                    ...item,
                    index: Number(item.index) - materials.length,
                    saveIs: false
                })

            state.specificationMaterialList =  afterList.concat(beforeList)
        }
      
        state.specificationMaterialSaveIs = false
    },
    SPECIFICATION_MATERIAL_ADD(state, material){

        let oldMaterial = state.specificationMaterialList.filter(item => item.fullname == material.fullname & material.vendor == item.vendor & material.type == item.type)[0]

        console.log('old material')
        console.log(oldMaterial.index)

        Vue.set(state.specificationMaterialList, oldMaterial.index - 1, {
            ...oldMaterial,
            saveIs: true,
            deleteIs: true,
            position: '',
            fullname: '',
            type: '',
            description: '',
            code: '',
            unit: '',
            quantity: 0,
            vendor: '',
            material: null,
            is_group: false
        })

        Vue.set(state.specificationMaterialList, material.index - 1, { 
            ...material,
            saveIs: true
        })
    }
}

const actions = {
    specificationMaterialAddRowActions( { commit } ){
        commit('SPECIFICATION_MATERIAL_ADD_ROW')
    },
    async specificationMaterialAddGroupActions( { commit, getters }, params ){
  
        let group = {
            id: '',
            rowKey: params.rowKey,
            position: '',
            fullname: 'Новая группа',
            is_group: true,
            index: getters.specificationMaterialListGetter.filter( item => item.rowKey == params.rowKey)[0].index,
            saveIs: false,
            deleteIs: true,
        }
        
        commit('SPECIFICATION_MATERIAL_ADD_GROUP', group)
    },
    async specificationMaterialGetListActions( { commit }, id ){

        commit('SPECIFICATION_MATERIAL_CLEAR')
        commit('SPECIFICATION_MATERIAL_LOADING')
        const data = await httpRequest('crm/specification/material/', 'post', { specificationId: id })
        commit('SPECIFICATION_MATERIAL_LOADING')

        if(data.code == 200){
            if(data?.data === null){
                for(var i = 0; i < 200; i++){
                    commit('SPECIFICATION_MATERIAL_ADD_ROW')
                }
            }else{
                for(i = 0; i < 200 + data.data.length; i++){
                    commit('SPECIFICATION_MATERIAL_ADD_ROW')
                }

                commit('SPECIFICATION_MATERIAL_SET_LIST', data.data)
            }
        }
        else{
            commit('SPECIFICATION_MATERIAL_ERROR', data?.data)
        }
    },
    async specificationMaterialAddRowAboveActions( { commit }, params ){
        commit('SPECIFICATION_MATERIAL_ADD_ABOVE', params)
    },
    async specificationMaterialAddRowBelowActions({ commit }, params){
        commit('SPECIFICATION_MATERIAL_ADD_BELOW', params)
    },
    async specificationMaterialGetHistoryListActions( { commit }, id ){

        console.log(commit)
        console.log(id)

    },
    async specificationMaterialClearCellActions( { commit }, selectionRangeIndexes ){
        commit('SPECIFICATION_MATERIAL_CLEAR_CELL', selectionRangeIndexes)
    },
    async specificationMaterialEditActions( { commit }, form){
        console.log('copy')
        console.log(form)
        commit('SPECIFICATION_MATERIAL_EDIT', form)
    },
    async specificationMaterialCopyRowActions({ commit }, { data, specificationId, selectionRangeKeys }){
        
        var param = {
            data: data,
            rangeKeys: selectionRangeKeys,
            specificationId: specificationId
        }

      /*   var emptyRows = getters.specificationMaterialListGetter.map( item => item.fullname == '').length
        var countEmptyRows = 0

        if( param.data.length - emptyRows >= 0 ){
            countEmptyRows = emptyRows - param.data.length + 5
        }else{
            countEmptyRows = param.data.length + 5
        }
 */
        // как вычеслить
        // 


        console.log(param.data.length)

        for(let i = 0; i <= param.data.length; i++){
            commit('SPECIFICATION_MATERIAL_ADD_ROW')
        }
        
        commit('SPECIFICATION_MATERIAL_COPY', param)
    },
    async specificationMaterialResetMaterialActions( { commit }, row ){
        commit('SPECIFICATION_MATERIAL_DELETE_FOR_POSITION', row.position)
    },
    async specificationMaterialSetMaterialActions( { commit, getters }, params){

        console.log(params)
    /*  var form = getters.specificationMaterialListGetter.filter( item => item.id == params.specificationMaterialId)[0]*/
        var code = getters.materialsUnitsListGetter.filter( item => item.title == params.material.unit.title )[0]  

        commit('SPECIFICATION_MATERIALS_SET_MATERIAL', { 
            rowKey: params.row.rowKey,
            specificationMaterialId: params.specificationMaterialId, 
            material: params.material, code: code 
        })
    },
    async specificationMaterialAoutofileActions( { commit }, { sourceSelectionData, targetSelectionData }){

        var params = {
            sourceSelectionData: sourceSelectionData,
            targetSelectionData: targetSelectionData
        }
        
        commit('SPECIFICATION_MATERIAL_AUTOFILE', params)
    },
    async specificationMaterialAddActions( { commit, getters }, specificationId){

        console.log(commit)
        console.log(getters)
        console.log(specificationId)
    },
    async specificationMaterialDeleteRowActions( { commit, getters }, params ){
        let materials
        materials = getters.specificationMaterialListGetter.filter( item => item.rowKey == params.key)
        commit('SPECIFICATION_MATERIAL_DELETE_ROWS', materials)

    },
    async specificationMaterialDeleteRowsActions( {commit, getters }, params){

        console.log(params)

        let materials
        materials = getters.specificationMaterialListGetter.slice(params.start, params.end + 1)

        console.log(materials)
        commit('SPECIFICATION_MATERIAL_DELETE_ROWS', materials)
    },
    async specificationMaterialSaveActions( { commit, getters }, params){

        // Создать материал

        commit('SPECIFICATION_MATERIAL_EDIT_LOADING')

        let materialListAdd = getters.specificationMaterialListGetter.filter(item => item.id == '' && item.is_group != true).filter(item => item.fullname != '' && item.unit != '')

        for(let i = 0; i < materialListAdd.length; i++){
            let data = await httpRequest('crm/specification/material/add', 'post', {
                unit_code: getters.materialsUnitsListGetter.filter( item => item.title == materialListAdd[i].unit )[0]?.code || '006',
                ...materialListAdd[i],
                specificationId: params,
                material: typeof materialListAdd[i].material === 'undefined' || materialListAdd[i].material == null ? '' : materialListAdd[i].material.id
            })
            
            if(data.code == 500){
                commit('SPECIFICATION_MATERIAL_ERROR', 'Ошибка на сервере')
            }
            else if(data.code == 200){
                commit('SPECIFICATION_MATERIAL_ADD', data.data)
            } else {
                commit('SPECIFICATION_MATERIAL_ERROR', data.data)
            }
        }

        // Создать группу

        let groupeListAdd = getters.specificationMaterialListGetter
            .filter(item => item.id == '' && item.is_group == true && item.fullname != '').map(item => {
                const select = {}

                select.position = item.position
                select.fullname = item.fullname
                select.isGroup = item.is_group
                select.index = item.index
                return select
            })

        for(let i = 0; i < groupeListAdd.length; i++){
            
            let data = await httpRequest('crm/specification/material/add', 'post', {
                ...groupeListAdd[i],
                specificationId: params,
                isGroup: true
            })

            if(data.code == 500){
                commit('SPECIFICATION_MATERIAL_ERROR', 'Ошибка на сервере')
            }
            else if(data.code == 200){
                commit('SPECIFICATION_MATERIAL_ADD', data.data)
            } else {
                commit('SPECIFICATION_MATERIAL_ERROR', data.data)
            }
        }

        // Удалеине

        getters.specificationMaterialDeleteListGetter.map( item => httpRequest('crm/specification/material/', 'delete', { 
                specificationId: params,
                specificationMaterialId: item.id
            }).catch( (error) => console.log(error) )
        )

        // Редактировать

        let materialListEdit = getters.specificationMaterialListGetter.filter(item => item.id != '' && item.saveIs === false && item.is_group !== true)

        for(let i = 0; i < materialListEdit.length; i++){
            console.log(materialListEdit[i])
            let data = await httpRequest('crm/specification/material/', 'patch', {
                position: materialListEdit[i].position,
                fullname: materialListEdit[i].fullname,
                type: materialListEdit[i].type,
                description: materialListEdit[i].description,
                quantity: materialListEdit[i].quantity,
                code: materialListEdit[i].code,
                vendor: materialListEdit[i].vendor,
                specificationId: params,
                specificationMaterialId: materialListEdit[i].id, 
                unit_code: getters.materialsUnitsListGetter.filter( item => item.title == materialListEdit[i].unit )[0]?.code || '006',
                material: typeof materialListEdit[i].material === 'undefined' ? '' : materialListEdit[i].material.id
            })

            if(data.code == 500){
                commit('SPECIFICATION_MATERIAL_ERROR', 'Ошибка на сервере')
            } 
        }


        let groupListEdit = getters.specificationMaterialListGetter.filter(item => item.id != '' && item.saveIs == false && item.is_group == true)


        for(let i = 0; i < groupListEdit.length; i++){
            let data = await httpRequest('crm/specification/material/', 'patch', {
                ...groupListEdit[i],
                specificationMaterialId: groupListEdit[i].id, 
                specificationId: params,
                is_group: true
            })
            if(data.code == 500){
                commit('SPECIFICATION_MATERIAL_ERROR', 'Ошибка на сервере')
            } 
        }

        commit('SPECIFICATION_MATERIAL_EDIT_LOADING')

        commit('SPECIFICATION_MATERIAL_SAVE')

        /*commit('SPECIFICATION_MATERIAL_EDIT_LOADING')
        let materialListAdd = getters.specificationMaterialListGetter
            .filter(item => item.id == '' && item.is_group != true)
            .filter(item => item.fullname != '' && item.unit != '')

        console.log('materialListAdd')
        console.log(materialListAdd)

        let materialListEdit = getters.specificationMaterialListGetter.filter(item => item.id != '' && item.saveIs == false && item.is_group != true)
        
        for(let i = 0; i < materialListAdd.length; i++){
            console.log('add')
            let data = await httpRequest('crm/specification/material/add', 'post', {
                unit_code: getters.materialsUnitsListGetter.filter( item => item.title == materialListAdd[i].unit )[0]?.code || '006',
                ...materialListAdd[i],
                specificationId: params,
                material: typeof materialListAdd[i].material === 'undefined' || materialListAdd[i].material == null ? '' : materialListAdd[i].material.id
            })
            
            console.log(data)

            if(data.code == 500){
                commit('SPECIFICATION_MATERIAL_ERROR', 'Ошибка на сервере')
            }
            else if(data.code == 200){
                commit('SPECIFICATION_MATERIAL_ERROR', data.data)
            }

        }

        getters.specificationMaterialDeleteListGetter.map( item => httpRequest('crm/specification/material/', 'delete', { 
                specificationId: params,
                specificationMaterialId: item.id
            }).catch( (error) => console.log(error) )
        )

        console.log(materialListEdit)

        for(let i = 0; i < materialListEdit.length; i++){
            console.log(materialListEdit[i])
            let data = await httpRequest('crm/specification/material/', 'patch', {
                position: materialListEdit[i].position,
                fullname: materialListEdit[i].fullname,
                type: materialListEdit[i].type,
                description: materialListEdit[i].description,
                quantity: materialListEdit[i].quantity,
                code: materialListEdit[i].code,
                vendor: materialListEdit[i].vendor,
                specificationId: params,
                specificationMaterialId: materialListEdit[i].id, 
                unit_code: getters.materialsUnitsListGetter.filter( item => item.title == materialListEdit[i].unit )[0]?.code || '006',
                material: typeof materialListEdit[i].material === 'undefined' ? '' : materialListEdit[i].material.id
            })

            if(data.code == 500){
                commit('SPECIFICATION_MATERIAL_ERROR', 'Ошибка на сервере')
            } 
        }

        // ------------------------------------------ Group Add -----------------------------------------------//

        let groupListAdd = getters.specificationMaterialListGetter
            .filter(item => item.id == '' && item.is_group == true && item.fullname != '').map(item => {
                const select = {}
                
                select.position = item.position
                select.fullname = item.fullname
                select.isGroup = item.is_group
                select.index = item.index
                return select
            })

        console.log(groupListAdd)

        for(let i = 0; i < groupListAdd.length; i++){
            
            let data = await httpRequest('crm/specification/material/add', 'post', {
                ...groupListAdd[i],
                specificationId: params,
                isGroup: true
            })

            if(data.code == 500){
                commit('SPECIFICATION_MATERIAL_ERROR', 'Ошибка на сервере')
            } 
        }

        console.log(groupListAdd)
        // ------------------------------------------ Group Edit ----------------------------------------------//

        console.log('edit groupe')
        let groupListEdit = getters.specificationMaterialListGetter
            .filter(item => item.id != '' && item.saveIs == false && item.is_group == true)

        console.log(groupListEdit)

        commit('SPECIFICATION_MATERIAL_EDIT_LOADING')

        for(let i = 0; i < groupListEdit.length; i++){
            let data = await httpRequest('crm/specification/material/', 'patch', {
                ...groupListEdit[i],
                specificationMaterialId: groupListEdit[i].id, 
                specificationId: params,
                is_group: true
            })

            if(data.code == 500){
                commit('SPECIFICATION_MATERIAL_ERROR', 'Ошибка на сервере')
            } 
        }
        */

    },

}

const getters = {
    specificationMaterialListGetter: (state) => state.specificationMaterialList,
    specificationMaterialByKeyGetter: (state) => (key) => state.specificationMaterialList.filter( item => item.rowKey == key),
    specificationMaterialHistoryListGetter: (state) => state.specificationMaterialHistoryList,
    specificationMaterialErrorGetter: (state) => state.specificationMaterialError,
    specificationMaterialDirectoryListGetter: (state) => state.specificationMaterialDirectoryList,
    specificationMaterialLoadingGetter: (state) => state.specificationMaterialLoading,
    specificationMaterialEditLoadingGetter: (state) => state.specificationMaterialEditLoading,
    specificationMaterialDeleteListGetter: (state) => state.specificationMaterialDeleteList,
    specificationMaterialSaveIsGetter: (state) => state.specificationMaterialSaveIs,
}

const state = () => ({
    specificationMaterialList: [],
    specificationMaterialHistoryList: [],
    specificationMaterialDirectoryList: [],
    specificationMaterialError: '',
    specificationMaterialLoading: false,
    specificationMaterialEditLoading: false,
    specificationMaterialDeleteList: [],
    specificationMaterialSaveIs: true,

    specificationMaterialIZMList: [],
    currentIZM: {}
})

export default {
    mutations,
    getters,
    actions,
    state,
}
