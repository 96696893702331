import { httpRequest } from '@/services/httpRequest.service'
/* import Vue from 'vue'  */

const mutations = {
    SPECIFICATION_SUPPLY_SET_LIST(state, data){
        console.log(data.data)
        state.specificationSupplyError = ''
        state.specificationSupplyList = data.data
    },
    SPECIFICATION_SUPPLY_LOADING(state){
        state.specificationSupplyLoading = !state.specificationSupplyLoading
    },
    SPECIFICATION_SUPPLY_ERROR(state, error){
        state.specificationSupplyError = error
    },
}

const actions = {
    async specificationSupplySetListActions( { commit } ){
        commit('SPECIFICATION_SUPPLY_LOADING')
        const data = await httpRequest('crm/master/specifications', 'post', {})
        commit('SPECIFICATION_SUPPLY_LOADING')

        if(data.code == 200){
            commit('SPECIFICATION_SUPPLY_SET_LIST', data)
        }
        else{
            commit('SPECIFICATION_SUPPLY_ERROR', data.data)
        }
    },
}

const getters = {
    specificationSupplyListGetter: (state) => state.specificationSupplyList,
    specificationSupplyLoadingGetter: (state) => state.specificationSupplyLoading,
}

const state = () => ({
    specificationSupply: {},
    specificationSupplyList: [],
    specificationSupplyLoading: false,
})

export default {
    mutations,
    getters,
    actions,
    state,
}

